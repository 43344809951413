
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'TimeSlots',
  components: {
    TimeField: () => import('@/components/base/TimeField.vue'),
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    rules: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    canAddTimeSlot (): boolean {
      return this.value.every((timeSlot: any) => timeSlot.startAt && timeSlot.endAt)
    },
  },
  methods: {
    deleteTimeSlot (index: number) {
      this.value.splice(index, 1)
    },
    addTimeSlot () {
      this.value.push({ startAt: null, endAt: null })
    },
  },
})
